import { Helmet } from 'react-helmet-async';
import gql from 'graphql-tag';
import { useEffect } from 'react';
import { css, cx } from '@linaria/core';
import { PageHeadline } from './PageHeadline.jsx';
import globals from '../utils/globals';
import { makeMemoFragment } from '../utils/graphql';
import { colors } from '../utils/css';

export const vendorSettingsFragment = makeMemoFragment({
  name: 'VendorSettings',
  fragment() {
    return gql`fragment ${this.name} on PrivacySettings {
      title
      namedUrl
    }
  `;
  },
});

export const VendorSettings = ({ className, privacySettings }) => {
  useEffect(() => {
    const originalState = globals.window.history.state;
    if (globals.window.cmp_cdid) {
      globals.window.location.replace(privacySettings.namedUrl);
    }
    globals.window.history.replaceState(originalState, '', originalState?.referrer);
    import('./ConsentManagement/cmp-style-overwrite.scss');
  }, [privacySettings.namedUrl]);

  return (
    <div className={cx(vendorSettingsClass, className)}>
      <PageHeadline>{privacySettings.title}</PageHeadline>
      <div id="cmpinlinepreferencesbox"></div>
      <Helmet>
        <link rel="stylesheet" href="https://consent.dwmirror.de/delivery/cmpinline.min.css" />
      </Helmet>
    </div>
  );
};

export const vendorSettingsClass = css`
  #cmpinlinepreferencesbox {
    .cmptxt_btn_back {
      display: none !important;
    }
  }
`;

VendorSettings.darkStyles = cx(
  PageHeadline.darkStyles,
  css`#cmpinlinepreferencesbox {
    background-color: ${colors.DARK_BLUE_GREY_01} !important;
  }`,
);
